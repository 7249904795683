import React, {useContext, useEffect, useRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useNavigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {UserContext} from '../../globalContext';
import axiosInstance from '../../axiosInstance';
import click from '../../static/media/music/click.mp3'
import women from '../../images/ludo/woman.png';
import back from "../../images/ludo/back01.png";
import hestoryicon from '../../images/ludo/joinvs.png'
import home from '../../images/ludo/kkkkk.png'
import profile02 from '../../images/ludo/prfils.png'
import rewards from '../../images/ludo/rewardlast.png'
import walletman from '../../images/ludo/invant.png'






const Wallet = props => {
const audioRef = useRef(null);

  const user = useContext(UserContext);
  let navigate = useNavigate();
  const [walletData , setWallet] = useState(null)
  const [walletAmount , setWalletAmount] = useState(null)

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user.userId == null) {
        window.location.reload(true);
      }
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWallet();
    checkRecharge();
  };
const checkRecharge = () =>{
  axiosInstance.get(`/payment/${user.userId}`).then((res)=>{

    if(res.data.data!==null && res.data.data!==undefined){

      verifyRecharge(res.data.data)
    }
  })
}
const verifyRecharge = (e) =>{
  const data={
    user_token: "eb0c44f223f310f7a6bc94432b44d9e9",
   order_id: e.orderId,
    //order_id: 8787772321801,
  } 


  axiosInstance.post(`/roomeCode/payment/check`,data).then((res)=>{


    if(res.data.result.status === "SUCCESS"){
      axiosInstance.put(`/payment/${e._id}` ,{status:"success"}).then((res) => {
        if (res.data && res.data.responseCode === 1) {
          putWallet(e);
          
    }
  })
}
    else{
      if(res.data.result.status === "PENDING"){
        axiosInstance.put(`/payment/${e._id}` ,{status:"failed"}).then((res) => {
          if (res.data && res.data.responseCode === 1) {
           
          } 
        });
      }}
    });
      }
    
 
  const getWallet = () => {
    axiosInstance.get(`/wallet/${user.userId}`).then((res) => {
        setWallet(res.data.data)
        setWalletAmount(res.data.data.depositeAmount+res.data.data.winningAmount)
    });
  }
  const onRedirect = (e) => {
    audioRef.current.play();
    navigate(`/${e}`)
   };
  var putP = 1 
  const putWallet = (e) => {
    axiosInstance.get(`/wallet/${user.userId}`).then((res) => {
      putP = putP +1
    
      if(putP===2){
        const data = {
          depositeAmount : res.data.data.depositeAmount+e.amount
        }
       axiosInstance.put(`/wallet/${user.userId}` , data).then((response) => {
        getWallet();

         });
     
      }
        
    });
    
  }

  const [startX, setStartX] = useState(null);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };
  
  
const handleTouchMove = (e) => {
  if (!startX) return;
  const currentX = e.touches[0].clientX;
  const difference = startX - currentX;
  if (Math.abs(difference) > 50) {
    if (difference > 0) {
      navigate('/History')
      console.log('Swipe left');
    } else {
      navigate('/HomeScreen')
      console.log('Swipe right');
    }
    setStartX(null);
  }
};
  return (
   <div className="addfi " 
   style={{ width: '100%', height: '100vh', backgroundColor: '#f0f0f0' }}>
     <div className='realludokingsize bgclr' style={{paddingBottom:'100%'}}>
    <div className='p-3 pe-0'>
    <div className=" d-flex align-items-center justify-content-between ">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <a href='#/HomeScreen' className=''>
            <button className="btn m-0 p-0 ">
              <span className="text-capitalize"><img className='backimg' src={back} alt="" /></span>
            </button>
          </a>
          <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
             <div className="bgredg0203  myadd d-flex  p-1 align-items-center justify-content-between">
            <div>
            <img src={women} className="hello" alt="" /><span>{user.userName.substring(0, 10)}...
               </span>
            </div>
            <div >
              <h6 className="p-1 rajauto" style={{marginBottom:'0px',marginRight:'1px',background:'yellow',borderRadius:'20px',}}><span style={{padding:"5px",fontSize:"14px"}}>&#x20B9;{walletAmount}</span></h6>
            </div>
             </div>
           
              </div>
        </div>
       
      </div>
    </div>
     <div className="col-12 g-0 iframe-sec p-3 realludokingsize pb-5">
 
     
      <div className="mb-3 card02">
      <div class=" text-muted d-flex bgtable align-items-center  justify-content-center pt-3">
             <div className='d-flex bgtable align-items-center  text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
              
             <span className="d-flex align-items-center">
                <span className=' text-white'>Deposit Chips</span> 

               </span>

               </div>
</div>
      
        <div class="card-body"><div><div><div role="alert" class="fade alert alert-primary show">यह चिप्स Spin &amp; Win एवं Buy की गई चिप्स है इनसे सिर्फ़ गेम खेले जा सकते है ॥ Bank या UPI में निकाला नहीं जा सकता है</div><div class="d-flex align-items-center justify-content-center px-2"><div class="d-flex flex-column align-items-center justify-content-center"><span class="text-white text-capitalize fw-bold">chips</span>
       {walletData !== null  &&<span class="text-white fs-4">{walletData.depositeAmount}</span>}
        </div></div><div class="d-flex flex-column align-items-stretch pt-4"><a class="text-decoration-none" href="#/Recharge"><div class="d-grid"><button class="btn btn-success btn-lg text-capitalize mb-2">add</button></div></a></div></div></div></div>
       
      </div>
      
      <div className="card02 ">
      <div class=" text-muted d-flex bgtable align-items-center  justify-content-center pt-3">
             <div className='d-flex bgtable align-items-center  text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
              
             <span className="d-flex align-items-center">
                <span className=' text-white'> winning chips</span> 

               </span>

               </div>
</div>
      
        <div class="card-body ">
          <div>
            <div>
              <div role="alert" class="fade alert alert-primary show">
                यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें Bank या
                UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला जा सकता है
              </div>
              <div class="d-flex align-items-center justify-content-center px-2">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <span class="text-capitalize text-white fw-bold">chips</span>
                {walletData !== null  &&  <span class="text-white fs-4">{walletData.winningAmount}</span>}
                </div>
              </div>
              <div class="d-flex flex-column align-items-stretch pt-4 pb-2">
                <a class="text-decoration-none" href="#/Withdraw">
                  <div class="d-grid">
                    <button class="btn btn-success btn-lg text-capitalize">
                      withdraw
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style={{marginBottom: '50px'}}>
        <ul className="list-group"></ul>
      </div>
   </div>
    </div>
    <div class="button-container">
    <audio ref={audioRef} src={click} />
      <button onClick={()=>onRedirect("wallet")}  class="button02 addleft">
      <img style={{width:"45px"}}  className="home01"  src={rewards} alt="" />
     
      </button>
   
      <button onClick={()=>onRedirect("profile")}  class="button02" >
      <img style={{width:"36px"}} className="home01"   src={profile02} alt="" />
      </button>
      <button onClick={()=>onRedirect("homescreen")}  class="button02">
      <img style={{width:"45px"}} className="home01 homeeee"  src={home} alt="" />
      </button>
      <button onClick={()=>onRedirect("wallet")}  class="button02">
      <img style={{width:"40px"}}  src={walletman} className="home01" alt="" />
      </button>
      <button onClick={()=>onRedirect("history")}  class="button02 addright">
      <img style={{width:"45px"}}  className="home01"  src={hestoryicon} alt="" />

      </button>
    </div>
   </div>
  );
};

export default Wallet;
