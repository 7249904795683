import React, { useContext,useEffect,useRef,useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import ludo from "../../images/ludo/ludoavt.svg"
import winimg from "../../images/ludo/winimg.webp"
import back from "../../images/ludo/back01.png";
import desiaadmi from "../../../src/images/ludo/desiaadmi.png"
import { Button } from "react-bootstrap";
import axiosInstance from "../../axiosInstance";
import women from '../../images/ludo/woman.png';
import walletin from '../../images/ludo/wallet - Copy.png'
import insta from '../../images/ludo/instagram.png'
import face from '../../images/ludo/facebook.png'
import what from '../../images/ludo/whatsapp.png'

import hestoryicon from '../../images/ludo/joinvs.png'
import home from '../../images/ludo/kkkkk.png'
import profile02 from '../../images/ludo/prfils.png'
import rewards from '../../images/ludo/rewardlast.png'
import walletman from '../../images/ludo/invant.png'

import av01 from '../../images/avtar/av01.svg'
import av02 from '../../images/avtar/av02.svg'
import av03 from '../../images/avtar/av03.svg'
import av04 from '../../images/avtar/av04.svg'
import av05 from '../../images/avtar/av05.svg'
import av06 from '../../images/avtar/av06.svg'
import av07 from '../../images/avtar/av07.svg'
import av08 from '../../images/avtar/av08.svg'
import av09 from '../../images/avtar/av09.svg'

import av10 from '../../images/avtar/av10.svg'
import av11 from '../../images/avtar/av11.svg'
import av12 from '../../images/avtar/av12.svg';








import click from '../../static/media/music/click.mp3'
import Wallet from "./wallet";
const Profile = (props) => {

  const onRedirect = (e) => {
    audioRef.current.play();
    navigate(`/${e}`)
   };
  const user = useContext(UserContext);
  let navigate = useNavigate();
const [userData , setUserData] = useState(null);
const [walletData , setWalletData] = useState(null);
const [gameData , setGameData] = useState(null);
const [walletAmount , setWalletAmount] = useState(null)
const [isActive, setIsActive] = useState(false);
const handleClick02 = () => {
  setIsActive(!isActive); // Toggle the state
}
  useEffect(() => {
    
    let mounted = true;
    if (mounted) {

   
      if (user.userId == null) {
       window.location.reload(true);
      }

      
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWallet();
    getUser();
    getGame();
  }
  const  getUser = () =>{
    axiosInstance.get(`/member/${user.userId}`).then((res)=>{
      console.log("member",res.data.data)
      setUserData(res.data.data);
    })
  } 
  const  getWallet = () =>{
    axiosInstance.get(`/wallet/${user.userId}`).then((res)=>{
      console.log("member",res.data.data)
      setWalletData(res.data.data);
      setWalletAmount(res.data.data.depositeAmount+res.data.data.winningAmount)

    })
  } 
  var gameC = []
var getG = 1 
  const getGame = () =>{
    getG = getG +1
   if(getG === 2) {
    axiosInstance.get(`/game/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      console.log("length",response.data.data.length)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame2()
    }
    else{
      getGame3();
     }
    });
    
   }
  }
var getG2 = 1 

  const getGame2 = () =>{
    getG2 = getG2 +1
   if(getG2 === 2) {
    axiosInstance.get(`/game/runing5/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame3();
      }
      else{
        getGame3();
       }
    });

   }
  }
  var getG3 = 1 

  const getGame3 = () =>{
    getG3 = getG3 +1
   if(getG3 === 2) {
    axiosInstance.get(`/game2/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
        );
        gameC.push(...sortedData)
        getGame4();
      }
     else{
      getGame4();
     }

    });
    
   }
  }
  var getG4 = 1 

  const getGame4 = () =>{
    getG4 = getG4 +1
   if(getG4 === 2) {
    axiosInstance.get(`/game2/runing5/${user.userId}`).then((response) => {
      console.log(response.data.data)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)

    });
    const sortedData = [...gameC].sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
    );
    console.log("gameC222",sortedData.length)

    setGameData(sortedData.length)
   }
  }
  const handleLogout = () => {
    audioRef.current.play();
    localStorage.clear();
    // navigate(`${environment.url.AUTH_URL}`, { replace: true });
    // navigate('/LoginScreen', { replace: true });
    // window.location.href=`${environment.url.AUTH_URL}`;
    window.location.href = `#/LoginScreen`;
  }   


////for tap voice/////
const audioRef = useRef(null);

  const onEditProfile = () => {
    // Play the tap sound
    audioRef.current.play();
    //audioRef.current.pause();
  };

////for tap voice/////



const [startX, setStartX] = useState(null);

const handleTouchStart = (e) => {
  setStartX(e.touches[0].clientX);
};

const handleTouchMove = (e) => {
  if (!startX) return;
  const currentX = e.touches[0].clientX;
  const difference = startX - currentX;
  if (Math.abs(difference) > 50) {
    if (difference > 0) {
      navigate('/HomeScreen')
      console.log('Swipe left');
    } else {
      navigate('/Wallet')
      console.log('Swipe right');
    }
    setStartX(null);
  }
};





  return (
   <div  className="realludokingsize" 
   style={{ width: '100%', height: '100vh', backgroundColor: '#f0f0f0' }}> 
    <div className="addfi ">
   <div className=' realludokingsize bgclr' style={{paddingBottom:'100%'}}>

  <div className='p-3 pe-0' >
  <div className=" d-flex align-items-center justify-content-between ">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <a href='#/HomeScreen' className=''>
            <button className="btn m-0 p-0 ">
              <span className="text-capitalize"><img className='backimg' src={back} alt="" /></span>
            </button>
          </a>
          <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
             <div className="bgredg0203  myadd d-flex  p-1 align-items-center justify-content-between">
            <div>
            <img src={women} className="hello" alt="" /><span>{user.userName.substring(0, 10)}...
               </span>
            </div>
            <div >
              <h6 className="p-1 rajauto" style={{marginBottom:'0px',marginRight:'1px',background:'yellow',borderRadius:'20px',}}><span style={{padding:"5px",fontSize:"14px"}}>&#x20B9;{walletAmount}</span></h6>
            </div>
             </div>
           
              </div>
        </div>
       
      </div>
  </div>
 
  <div className="col-12 mx-auto g-0  iframe-sec p-3">
     
{/*    
     <div className="d-flex align-items-center justify-content-between">
                  <div style={{width:"20%"}}>
                    <div className="">
                      <img src={refer} style={{width:"80%"}} alt="" />
                    </div>
                    <div className="">
                      <img src={Support} className="mt-3" style={{width:"80%"}} alt="" />
                    </div>
                  </div>
                  <div style={{width:"50%"}}>
                    <img className="mainaadmi" src={desiaadmi} style={{width:'100%'}} alt="" />
                  </div>
                  <div style={{width:"20%",textAlign:'end'}}>
                    <div className="">
                      <img src={qa} style={{width:"80%"}} alt="" />
                    </div>
                    <div className="">
                      <img src={member} className="mt-3" style={{width:"80%"}} alt="" />
                    </div>
                  </div>
                  
                  
                </div> */}
                <div className=" card02 p-1 mb-5 " id="mtminus">
                <div >
 
       <div id="card02" className="">
           <div id="upper-bg">
               <img src={ludo} alt="profile-pic" class="profile-pic" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"/>
           </div>
           <div id="lower-bg">
             <div class="text">
               <p class="name">  {userData !== null && <h>{userData.name}</h>}</p>
               <p class="title">{userData !== null &&  <h>{userData.mobile}</h>}</p>
             </div>
             
             <div className="card-body">
         <div className="g-0 gx-2 mb-2 row">
           {/* First column */}
           <div className="col">
             <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
               <div className="text-capitalize text-start px-2 card-header" style={{ fontSize: '0.9rem' }}>
                 <div className="hstack gap-1 rajuda">
                 <img src={winimg} width="16px" height="16"  style={{marginRight:5,}} alt="games played" />
                   <span>games played</span>
                 </div>
               </div>
               <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">0.00</div>
             </div>
           </div>
           {/* Second column */}
           <div className="col">
             <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
               <div className="text-capitalize text-start px-2  card-header"  style={{ fontSize: '0.9rem' }}>
                 <div className="hstack gap-1 rajuda">
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" style={{marginRight:5,}} height="16" fill="currentColor">
                     <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"></path>
                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                     <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                   </svg>
                   <span>chips won</span>
                 </div>
               </div>
               <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">0.00</div>
             </div>
           </div>
         </div>
   
   
           </div>
           
             <div id="l-c-s" >
               <div class="num">
                 <img width="60" height="60" src={what} alt="facebook-like--v1"/>
                 
               </div>
               <div class="num dvr"></div>
               <div class="num">
                 <img width="60" height="60" src={face} alt="speech-bubble--v1"/>
                
               </div>
               <div class="num dvr"></div>
               <div class="num">
                 <img width="60" height="60" src={insta} alt="share"/>
                
               </div>
             </div>
            <div id="upper-bg">
            <div id="btn">
             <audio ref={audioRef} src={click} />
               <button class="custom-btn btn-1" onClick={onEditProfile} data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">  Edit Profile</button>
               <button class="custom-btn btn-2" onClick={handleLogout} >Logout</button>
             </div>
            </div>
          
           </div>
       </div>
       </div>
                </div>
     
   <div class="offcanvas offcanvas-top myadding" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-header" style={{background:'#000'}}>
    <h5 id="offcanvasTopLabel" style={{color:'#fff'}}>Select Avatar</h5>
    <button type="button" class="btn-close text-reset me-2" data-bs-dismiss="offcanvas" aria-label="Close">&#10006;</button>
  </div>
  <div class="offcanvas-body"style={{background:'#000'}}>
  <div class="pt-3 pb-2">
   <div class="my-2 card02">
     
      <div class="card-body">
         <div class="row row-cols-4 g-2">

  
            <div class="col"><img src={av01} width="60" alt="" onClick={handleClick02} className={isActive ? 'active' : 'border border-primary border-4 rounded-circle'}/></div>
            <div class="col"><img src={av02} width="60" alt=""  class="border border-white rounded-circle"/></div>
            <div class="col"><img src={av03} width="60" alt=""  class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av04} alt=""  width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av05} alt=""   width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av06} alt=""  width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av07} alt=""  width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av08} alt=""  width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av09} alt=""  width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av10} alt=""  width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av11} alt=""  width="60" class="border border-white rounded-circle"/></div>
            <div class="col"><img  src={av12} alt=""  width="60" class="border border-white rounded-circle"/></div>
         </div>
         <div class="d-grid mb-2  mt-4"><button type="button"  data-bs-dismiss="offcanvas" aria-label="Close" class="text-capitalize btn btn-primary" >save</button></div>
      </div>
   </div>
</div>
  </div>
</div>
       </div>
  </div>



  <div class="button-container">
    <audio ref={audioRef} src={click} />
      <button onClick={()=>onRedirect("wallet")}  class="button02 addleft">
      <img style={{width:"45px"}}  className="home01"  src={rewards} alt="" />
     
      </button>
   
      <button onClick={()=>onRedirect("profile")}  class="button02" >
      <img style={{width:"36px"}} className="home01"   src={profile02} alt="" />
      </button>
      <button onClick={()=>onRedirect("homescreen")}  class="button02">
      <img style={{width:"45px"}} className="home01 homeeee"  src={home} alt="" />
      </button>
      <button onClick={()=>onRedirect("wallet")}  class="button02">
      <img style={{width:"40px"}}  src={walletman} className="home01" alt="" />
      </button>
      <button onClick={()=>onRedirect("history")}  class="button02 addright">
      <img style={{width:"45px"}}  className="home01"  src={hestoryicon} alt="" />

      </button>
    </div>
   </div>
 </div>
     );
};

export default Profile;

